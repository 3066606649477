.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 15rem;
  vertical-align: top;
  overflow: hidden;
}
.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

